import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class DefaultVideo {
    public static TYPE: VideoFormatType = VideoFormat.DEFAULT;
    public static DISPLAY: VideoDisplayType = VideoDisplay.VIDEO;

    public static createVideoLink(): VideoLink {
        return new VideoLink(DefaultVideo.TYPE, DefaultVideo.DISPLAY);
    }
}
