import { Component } from '@angular/core';

@Component({
  selector: 'app-popup-footer',
  templateUrl: './popup-footer.component.html',
  styleUrls: ['./popup-footer.component.scss']
})
export class PopupFooterComponent {

}
