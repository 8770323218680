<mm-navigation></mm-navigation>
<div class="micromate-learn-component micromate-learn-component-profile">
    <div class="micromate-learn-context">
        <div class="micromate-learn-context-info">
            <h2 class="micromate-profile-context-title">{{'profile.title'| translate}}</h2>
            <mm-profile
                class="micromate-profile-context"
                [profileData]="profileData$"
                [locale]="locale$"
                [darkMode]="false"
                [openAsCard]="false"
                [profileUpdated]="profileUpdated$"
                [doNotAllowToEdit]="(authUntilUserRoleService.assertAdmin() | async) && learnerId !== undefined"
                (savePseudonymSettings)="savePseudonymSettings($event)"
                (generatePseudonym)="generatePseudonym()"
                (changedMonthlyReport)="changeMonthlyReport($event)"
                (saveProfileData)="saveProfileData($event)">
            </mm-profile>
        </div>
        <div class="micromate-learn-context-footer">
            <app-button (click)="close()" type="secondary"> {{'profile.close'| translate}}</app-button>
        </div>
    </div>
</div>
