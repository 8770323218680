import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {StudyProgress, TabTypes} from '../../types/StudyProgress';
import {AuthUntilUserRoleService} from '../../../../../micromate-learn-web/src/app/core/authentication/auth-until-user-role.service';

@Component({
    selector: 'mm-study-progress',
    templateUrl: './study-progress.component.html',
    styleUrls: ['./study-progress.component.scss']
})
export class StudyProgressComponent implements OnInit, OnDestroy {
    @Input() studyProgressesData: Observable<StudyProgress[]>;
    @Input() darkMode: boolean;
    @Input() changDetectionAfterTurn: boolean;
    @Output() learnPackageActivated = new EventEmitter<string>();

    public studyProgresses: StudyProgress[];
    public isDataLoaded = false;
    public learningGoalsRange = [
        {
            value: 90,
            legend: 'expert'
        },
        {
            value: 80,
            legend: 'professional'
        },
        {
            value: 70,
            legend: 'skilled'
        },
        {
            value: 55,
            legend: 'advanced'
        },
        {
            value: 40,
            legend: 'beginners'
        },
        {
            value: 0,
            legend: 'newbie'
        }
    ];
    private subscription: Subscription;
    public isStartingLearningSessionInProgress: boolean = false;

    public isAdmin$: Observable<boolean>;

    constructor(private ref: ChangeDetectorRef,
                public roleService: AuthUntilUserRoleService,
                public translate: TranslateService) {
        this.isAdmin$ = roleService.assertAdmin();
    }

    public ngOnInit(): void {
        this.subscription = this.studyProgressesData.subscribe(studyProgressesData => {
            if (studyProgressesData !== undefined) {
                this.isDataLoaded = true;
                this.studyProgresses = studyProgressesData.map(studyProgress => {
                    studyProgress.studyProgressScaleName = this.getUserScaleName(studyProgress.studyProgress);
                    studyProgress.activeTab = 'learningProgress';
                    return studyProgress;
                });
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public changeTab(studyProgress: StudyProgress, tab: TabTypes): void {
        studyProgress.activeTab = tab;
        if (this.changDetectionAfterTurn) {
            this.ref.detectChanges();
        }
    }

    private getUserScaleName(studyProgress: number): string {
        return this.learningGoalsRange.filter(range => studyProgress >= range.value)[0].legend;
    }

    public async learnPackage(learningPackageId: string): Promise<void> {
        this.learnPackageActivated.emit(learningPackageId);
        this.isStartingLearningSessionInProgress = true;
    }
}
