import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-popup-wizard-footer',
    templateUrl: './popup-wizard-footer.component.html',
    styleUrls: ['./popup-wizard-footer.component.scss']
})
export class PopupWizardFooterComponent {

    @Output()
    public navigateForwardClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public navigateBackClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public isBackVisible: boolean;

    @Input()
    public isForwardVisible: boolean;

    @Input()
    public isForwardDisabled: boolean;

    @Input()
    public hasCustomContent: boolean;

    @Input()
    public forwardText: string;

}
