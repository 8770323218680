import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {LearnerRankingResponseDto} from '../../types/LearnerRankingResponseDto';
import {ScoreboardData} from '../../types/ScoreboardData';
import {RankingPeriod} from '../../types/RankingPeriod';
import {RankingService} from './ranking.service';

@Component({
    selector: 'mm-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit, OnDestroy {
    @Input()
    public rankingData: Observable<ScoreboardData>;

    @Input()
    public darkMode: boolean;

    @Output()
    public periodSelected: EventEmitter<RankingPeriod> = new EventEmitter<RankingPeriod>();

    public data: ScoreboardData;
    public activePeriod: RankingPeriod;
    private subscription: Subscription;
    public showSpinner: boolean = true;

    constructor(public scoreboardService: RankingService) {
    }

    public ngOnInit(): void {
        this.subscription = this.rankingData.subscribe(scoreboardData => {
            if (scoreboardData !== undefined) {
                this.showSpinner = false;
                this.data = scoreboardData;
                this.activePeriod = this.data.activePeriod;
            }
        });
    }

    public formatPoints(points: number): string {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }

    public switch(period: RankingPeriod): void {
        this.periodSelected.emit(period);
        this.activePeriod = period;
        this.showSpinner = true;
    }

    public isActiveLearnerInLeaders(ranking: LearnerRankingResponseDto): boolean {
        return !ranking.leaders.some(leader => leader.learnerId === ranking.activeLearner.learnerId);
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

}
