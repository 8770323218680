import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-popup-header-title',
    templateUrl: './popup-header-title.component.html',
    styleUrls: ['./popup-header-title.component.scss']
})
export class PopupHeaderTitleComponent {
    @Input()
    public title: string;
}
