import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

import {LocaleResponseDto} from './localeResponseDto';
import {EnvironmentService} from '../EnvironmentService';
import {BestMatchingLocaleResponseDto} from './bestMatchingLocaleResponseDto';

@Injectable({
    providedIn: 'root'
})
export class LocaleResource {

    constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {
    }

    public getAll(): Promise<LocaleResponseDto[]> {
        return firstValueFrom(this.httpClient.get<LocaleResponseDto[]>(`${this.environmentService.environment.serverUrl}/api/locals/all`).pipe(shareReplay()));
    }

    public getSupported(): Promise<LocaleResponseDto[]> {
        return firstValueFrom(this.httpClient.get<LocaleResponseDto[]>(`${this.environmentService.environment.serverUrl}/api/locals/supported`).pipe(shareReplay()));
    }

    public getBestMatching(locale: string): Promise<BestMatchingLocaleResponseDto> {
        return firstValueFrom(this.httpClient.get<BestMatchingLocaleResponseDto>(`${this.environmentService.environment.serverUrl}/api/locals/converter/best-matching-supported/${locale}`));
    }
}
