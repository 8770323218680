export type VideoFormatType = 'default' | 'youtube' | 'google-drive' | 'one-drive' | 'dartfish';

export enum VideoFormat {
    DEFAULT = 'default',
    YOUTUBE = 'youtube',
    GOOGLE_DRIVE = 'google-drive',
    ONE_DRIVE = 'one-drive',
    DARTFISH = 'dartfish'
}

export type VideoDisplayType = 'video' | 'iframe';

export enum VideoDisplay {
    VIDEO = 'video',
    IFRAME = 'iframe'
}
