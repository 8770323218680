import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {
    @Input()
    public type: 'info' | 'error' | 'warning' | 'success';
    @Input()
    public header: string;
    @Input()
    public content: string;
    @Input()
    public showIcon: boolean = true;
}
