<div class="mm-message-box"
     [class.mm-message-error-box]="type==='error'"
     [class.mm-message-info-box]="type==='info'"
     [class.mm-message-info-warning]="type==='warning'"
     [class.mm-message-info-success]="type==='success'">
    <app-icon *ngIf="showIcon && type==='error'" icon="circle-exclamation-light" class="mm-message-box-icon"></app-icon>
    <app-icon *ngIf="showIcon && type==='info'" icon="circle-info-light" class="mm-message-box-icon"></app-icon>
    <app-icon *ngIf="showIcon && type==='warning'" icon="exclamation-triangle-light"
              class="mm-message-box-icon"></app-icon>
    <app-icon *ngIf="showIcon && type==='success'" icon="circle-check-light" class="mm-message-box-icon"></app-icon>
    <div class="mm-message-box-content" [class.mm-message-box-content-with-icon]="showIcon">
        <div *ngIf="header !== undefined" class="mm-message-box-title" [innerHTML]="header"></div>
        <div *ngIf="content !== undefined" class="mm-message-box-content-text" [innerHTML]="content"></div>
        <ng-content></ng-content>
    </div>
</div>
