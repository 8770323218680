import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {LearnAuthService} from '../../core/authentication/learn-auth.service';
import {filter, map, switchMap} from 'rxjs/operators';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {LearnerRankingResponseDto} from '../../../../../micromate-learn-lib/src/lib/types/LearnerRankingResponseDto';
import {RankingHelper} from '../../../../../micromate-learn-lib/src/lib/components/ranking/rankingHelper';
import {ScoreboardData} from '../../../../../micromate-learn-lib/src/lib/types/ScoreboardData';
import {RankingPeriod} from '../../../../../micromate-learn-lib/src/lib/types/RankingPeriod';
import {LearnerPointsResponseDto} from '../../../../../micromate-learn-lib/src/lib/types/LearnerPointsResponseDto';
import {ScoreboardRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/scoreboard-ressource.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {TabRankingChangedPeriodType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabRankingChangedPeriodType';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    selector: 'mm-learning-scoreboard',
    templateUrl: './learning-scoreboard.component.html',
    styleUrls: ['./learning-scoreboard.component.scss']
})
export class LearningScoreboardComponent implements OnInit, OnDestroy {
    public scoreboardData: BehaviorSubject<ScoreboardData> = new BehaviorSubject<ScoreboardData>(undefined);
    public scoreboardData$ = this.scoreboardData.asObservable();
    public organizationExistPeriod = true;
    public rankingNotActiveInLearnerOrganization = false;
    public learnerId: string;
    public zitadelId: string;
    public activePeriod: RankingPeriod;
    public learnerPoints: LearnerPointsResponseDto;
    public scoreboard: LearnerRankingResponseDto;
    public paramsSubscription: Subscription;
    public params: ParamMap;
    public openInConversation: boolean;

    constructor(private activatedRoute: ActivatedRoute,
                private scoreboardService: ScoreboardRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                private authUntilUserRoleService: AuthUntilUserRoleService,
                private router: Router,
                private eventRecorderService: EventRecorderService) {
    }

    public ngOnInit(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.pipe(
            map(params => {
                this.params = params;
                this.openInConversation = params.get('openInConversation') === 'true';
            }),
            switchMap(() => this.authService.authentication$),
            filter((authentication: User) => authentication !== undefined))
            .subscribe(async (authentication: User) => {
                await this.getScoreboard(authentication);
                //await this.recordTabEvents(authentication);
            });
    }

    public ngOnDestroy(): void {
        if (this.paramsSubscription !== undefined) {
            this.paramsSubscription.unsubscribe();
        }
    }

    private async getScoreboard(authentication: User): Promise<void> {
        if (this.zitadelId !== undefined || this.learnerId !== undefined) {
            await this.subscribeOnActivePeriod(this.learnerId, this.zitadelId);
            await this.recordTabRankingChangedEvent();
        } else {
            const learnerId = this.params.get('learnerid') ?? undefined;
            if (learnerId === undefined) {
                this.zitadelId = authentication.sub;
                await this.getLearnerScoreboard(undefined, this.zitadelId);
                await this.recordTabOpenedEvent(this.zitadelId);
            } else {
                const isAdmin = await this.authUntilUserRoleService.assertAdmin().toPromise();
                if (!isAdmin) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.router.navigate(['/learn']);
                } else {
                    await this.getLearnerScoreboard(learnerId, undefined);
                }
            }
        }
    }

    private async getLearnerScoreboard(learnerId: string, zitadelId: string): Promise<void> {
        const learnerPointsPromise = this.scoreboardService.getLearnerPointsForLearn(learnerId, zitadelId);
        this.learnerPoints = await learnerPointsPromise;
        await this.subscribeOnActivePeriod(learnerId, zitadelId);
    }

    private async subscribeOnActivePeriod(learnerId: string, zitadelId: string): Promise<void> {
        this.rankingNotActiveInLearnerOrganization = await this.scoreboardService.getRankingActivatedStatusForLearn(learnerId, zitadelId);
        const scoreboardData: ScoreboardData = {
            learnerPoints: this.learnerPoints,
            rankingNotActiveInLearnerOrganization: this.rankingNotActiveInLearnerOrganization,
            learnerId: learnerId
        };

        if (this.rankingNotActiveInLearnerOrganization) {
            const activePeriodInfo = this.params.get('period') ?? undefined;
            this.activePeriod = activePeriodInfo === undefined ? 'current-month' : activePeriodInfo as RankingPeriod;
            const scoreboardHelper = new RankingHelper();
            const period = scoreboardHelper.getPeriod(this.activePeriod);
            const date = scoreboardHelper.getPeriodDate(this.activePeriod).format('YYYY-MM-DD');
            this.scoreboard = await this.scoreboardService.getScoreboardForLearn(learnerId, zitadelId, period, date);
            this.organizationExistPeriod = this.scoreboard !== undefined;

            scoreboardData.activePeriod = this.activePeriod;
            scoreboardData.organizationExistPeriod = this.organizationExistPeriod;
            scoreboardData.scoreboard = this.scoreboard;
        }
        this.scoreboardData.next(scoreboardData);
    }

    private async recordTabRankingChangedEvent(): Promise<void> {
        const activePeriodInfo = this.params.get('period') as TabRankingChangedPeriodType;
        await this.eventRecorderService.recordTabRankingChangedEventForLearn(this.learnerId, this.zitadelId, TabEventType.TabRankingChanged, activePeriodInfo);
    }

    private async recordTabOpenedEvent(zitadelId: string): Promise<void> {
        const tabOpenEventPayload = this.eventRecorderService.createTabOpenEventPayloadForLearn(this.openInConversation, this.rankingNotActiveInLearnerOrganization);
        await this.eventRecorderService.recordTabOpenedEventForLearn(undefined, zitadelId, TabEventType.TabRankingOpened, tabOpenEventPayload);
    }

    public periodSelected(period: RankingPeriod): void {
        const queryParams: Params = this.learnerId === undefined ? {period} : {learnerid: this.learnerId, period};
        void this.router.navigate(['learn/rangliste'], {
            queryParams,
            queryParamsHandling: 'merge'
        });
    }
}
