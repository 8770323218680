import {Injectable} from '@angular/core';
import {LocaleResource} from './locale-resource.service';
import {LocaleResponseDto} from './localeResponseDto';
import {BestMatchingLocaleResponseDto} from './bestMatchingLocaleResponseDto';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private static all: Promise<LocaleResponseDto[]>;
    private static supported: Promise<LocaleResponseDto[]>;

    constructor(private localeResource: LocaleResource) {
        LocaleService.all = this.localeResource.getAll();
        LocaleService.supported = this.localeResource.getSupported();
    }

    public getAll(): Promise<LocaleResponseDto[]> {
        return LocaleService.all;
    }

    public getSupported(): Promise<LocaleResponseDto[]> {
        return LocaleService.supported;
    }

    public async getBestMatching(locale: string): Promise<BestMatchingLocaleResponseDto> {
       return await this.localeResource.getBestMatching(locale);
    }
}

