import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-source-question-preview',
    templateUrl: './source-question-preview.component.html',
    styleUrls: ['./source-question-preview.component.scss']
})
export class SourceQuestionPreviewComponent {

    @Input()
    public sourceLabel: string;

    @Input()
    public sourceUrl: string;
}
