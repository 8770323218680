import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LoggerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {TeamsContextService} from '../../core/teams-context.service';
import {LearnerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/learner-ressource.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {Translation} from '../../../../../micromate-learn-lib/src/lib/services/Translation';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {LearnerProfile} from '../../../../../micromate-learn-lib/src/lib/types/LearnerProfile';
import {ProfileRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/profile-ressource.service';
import {PseudonymSettings} from '../../../../../micromate-learn-lib/src/lib/components/profile/PseudonymSettings';
import {ProfilePseudonymService} from '../../../../../micromate-learn-lib/src/lib/components/profile/profile-pseudonym.service';
import {app} from '@microsoft/teams-js';
import {ToastrService} from 'ngx-toastr';
import {UILocalizationService} from '../../../../../micromate-learn-lib/src/lib/services/locale/u-i-localization.service';

@Component({
    selector: 'mm-profile-profile',
    templateUrl: './profile-profile.component.html',
    styleUrls: ['profile-profile.component.scss']
})
export class ProfileProfileComponent implements OnInit, OnDestroy {
    public teamsContextServiceSubscription = new Subscription();
    public profileData: BehaviorSubject<LearnerProfile> = new BehaviorSubject<LearnerProfile>(undefined);
    public profileData$ = this.profileData.asObservable();

    public locale: Subject<string> = new Subject<string>();
    public locale$ = this.locale.asObservable();
    private userId: string;
    private profileUpdated: Subject<void> = new Subject<void>();
    public profileUpdated$: Observable<void> = this.profileUpdated.asObservable();

    constructor(public teamsContextService: TeamsContextService,
                private learnerService: LearnerRessource,
                private translate: TranslateService,
                private ref: ChangeDetectorRef,
                private titleService: Title,
                private eventRecorderService: EventRecorderService,
                private uiLocalizationService: UILocalizationService,
                private loggerService: LoggerRessource,
                private profileService: ProfileRessource,
                private profilePseudonymService: ProfilePseudonymService,
                private toastr: ToastrService) {
        this.titleService.setTitle('Micromate Profile');
    }

    public ngOnInit(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileProfile init');
        this.teamsContextServiceSubscription = this.teamsContextService.teamsContext$.subscribe(async teamsContext => {
            this.userId = teamsContext.user.id;
            this.loggerService.logFrontendInfoToBackend(`ProfileProfile ${JSON.stringify(teamsContext)}, userId: ${this.userId}`);
            if (this.userId === undefined) {
                throw new Error(`UserObjectId is undefined when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            // eslint-disable-next-line no-null/no-null
            if (this.userId === null) {
                throw new Error(`UserObjectId is null when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            if (this.userId.trim().length === 0) {
                throw new Error(`UserObjectId is empty when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            await this.getLearnerData(this.userId, teamsContext);
            await this.recordTabEvents(this.userId, teamsContext.page.subPageId);
        });
    }


    public ngOnDestroy(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileProfile destroyed');
        if (this.teamsContextServiceSubscription !== undefined) {
            this.teamsContextServiceSubscription.unsubscribe();
        }
    }

    public savePseudonymSettings(pseudonymSettings: PseudonymSettings): void {
        this.profileService.savePseudonymSettings(this.userId, pseudonymSettings).subscribe(async () => {
            const learnerProfilePromise = this.profileService.getLearnerProfile(this.userId);
            this.profileData.next(await learnerProfilePromise);
            this.ref.detectChanges();
        });
    }

    public generatePseudonym(): void {
        this.profilePseudonymService.pseudonymGenerationSubscription = this.profileService.generatePseudonym(this.userId).subscribe(pseudonym => {
            this.profilePseudonymService.setNewPseudonym(pseudonym);
            this.ref.detectChanges();
        });
    }

    public changeMonthlyReport(monthlyReportState: boolean): void {
        this.profileService.saveMonthlyLearningReportStatus(this.userId, monthlyReportState).subscribe(async () => {
            const learnerProfilePromise = this.profileService.getLearnerProfile(this.userId);
            this.profileData.next(await learnerProfilePromise);
            this.ref.detectChanges();
            this.toastr.success(this.translate.instant('profile.monthlyReportSaved') as string);
        });
    }

    private async getLearnerData(msteamsId: string, teamsContext: app.Context): Promise<void> {
        const languageDataPromise = this.learnerService.getLearnerLanguage(msteamsId, teamsContext);
        const learnerProfilePromise = this.profileService.getLearnerProfile(msteamsId);
        const languageData = await languageDataPromise;
        if (languageData === undefined) {
            throw new Error(`Could not find language in profile for msteamsId: ${msteamsId}`);
        }
        this.translate.use(new Translation().getTranslationLanguage(languageData.language));
        const profileData = await learnerProfilePromise;
        this.profileData.next(profileData);
        this.locale.next(profileData.locale);
        this.ref.detectChanges();
    }

    private async recordTabEvents(userId: string, subEntityId: string | { openInConversation: boolean; }): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEvent(userId, TabEventType.TabProfileOpened,
            this.eventRecorderService.createTabOpenedEventPayload(subEntityId));
    }

    public async saveProfileData(data: LearnerProfile): Promise<void> {
        const updatedProfileData = await this.profileService.updateProfileForTeams(this.userId, data);
        await this.uiLocalizationService.setLanguage(updatedProfileData.locale.split('-')[0]);
        this.locale.next(updatedProfileData.locale);
        this.profileUpdated.next();
    }
}
