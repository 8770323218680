import {Component, Inject} from '@angular/core';
import {LearnAuthService} from '../../learn-auth.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    templateUrl: './auth-error.component.html',
    styleUrls: ['./auth-error.component.scss']
})
export class AuthErrorComponent {
    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public login(): void {
        void this.authService.startLoginFlow(false);
    }
}
