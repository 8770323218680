<div class="micromate-profile-page"
     [class.micromate-profile-page-position-inherit]="!openAsCard">
    <div class="micromate-profile-profile" *ngIf="(profileData | async) as profileData">

        <div class="micromate-profile-info" *ngIf="profileData.email !== profileData.identifier">
            <div class="micromate-profile-info-title">
                {{ 'profile.identifier'| translate }}:
            </div>
            <div class="micromate-profile-info-data">
                {{ profileData.identifier }}
            </div>
        </div>

        <div class="micromate-profile-info">
            <div class="micromate-profile-info-title">
                <ng-container *ngIf="profileData.email === profileData.identifier">
                    {{ 'profile.emailAndIdentifier'| translate }}:
                </ng-container>
                <ng-container *ngIf="profileData.email !== profileData.identifier">
                    {{ 'profile.email'| translate }}:
                </ng-container>
            </div>
            <div class="micromate-profile-info-data">
                {{ profileData.email ?? '-' }}
            </div>
        </div>

        <div class="micromate-profile-info" *ngIf="profileData.displayName !== undefined">
            <div class="micromate-profile-info-title">
                {{ 'profile.name'| translate }}:
            </div>
            <div class="micromate-profile-info-data">
                {{ profileData.displayName }}
            </div>
        </div>
        <div class="micromate-profile-info">
            <div class="micromate-profile-info-title">
                {{ 'profile.organizationName'| translate }}:
            </div>
            <div class="micromate-profile-info-data">
                {{ profileData.organizationName !== undefined ? profileData.organizationName : ('profile.noOrganization'| translate) }}
            </div>
        </div>

        <hr class="mm-profile-profile-separator">

        <div class="micromate-profile-profile-pseudonyme"
             [class.micromate-profile-pseudonym-editor]="localeEditorActive">
            <div *ngIf="!localeEditorActive && !doNotAllowToEdit"
                 (click)="openLocaleEditor()"
                 class="micromate-profile-profile-pseudonyme-editor-button"
                 [title]="'profile.editButton'| translate">
                <app-icon [icon]="'pencil-light'"
                          class="micromate-profile-profile-pseudonyme-editor-button-icon"></app-icon>
            </div>
            <div *ngIf="!localeEditorActive" class="micromate-profile-pseudonym-info">
                <div class="micromate-profile-info">
                    <div class="micromate-profile-info-title">
                        {{ 'profile.locale'| translate }}
                    </div>
                    <div class="micromate-profile-info-data">
                        {{ loadedLocale | locale | async }}
                    </div>
                </div>
            </div>
            <div *ngIf="localeEditorActive" class="micromate-profile-pseudonym-info">
                <div class="micromate-profile-info micromate-profile-info-pseudonym">
                    <div class="micromate-profile-info-title">
                        {{ 'profile.locale'| translate }}
                    </div>
                    <div class="micromate-profile-info-data">
                        <form [formGroup]="localeFormData">
                            <app-locale-select formControlName="locale"></app-locale-select>
                        </form>
                    </div>
                </div>
                <div class="micromate-profile-pseudonym-editor-buttons">
                    <app-button [disabled]="localeFormData.disabled" (click)="cancelLocale()"
                                type="secondary"> {{ 'profile.cancel'| translate }}
                    </app-button>
                    <app-button [disabled]="!localeValueChanged" [action]="saveLocale" type="primary">
                        {{ 'profile.save'| translate }}
                    </app-button>
                </div>
            </div>

        </div>

        <ng-container *ngIf="profileData.usePseudonym !== undefined">
            <hr class="mm-profile-profile-separator mm-profile-profile-separator-pseudonym">
            <div class="micromate-profile-profile-pseudonyme"
                 [class.micromate-profile-pseudonym-editor]="pseudonymEditorActive">
                <div *ngIf="!pseudonymEditorActive && !doNotAllowToEdit"
                     (click)="openPseudonymEditor()"
                     class="micromate-profile-profile-pseudonyme-editor-button"
                     [title]="'profile.editButton'| translate">
                    <app-icon [icon]="'pencil-light'"
                              class="micromate-profile-profile-pseudonyme-editor-button-icon"></app-icon>
                </div>
                <div *ngIf="!pseudonymEditorActive" class="micromate-profile-pseudonym-info">
                    <div class="micromate-profile-info">
                        <div class="micromate-profile-info-title">
                            {{ 'profile.usePseudonym'| translate }}:
                        </div>
                        <div class="micromate-profile-info-data">
                            {{ (profileData.usePseudonym ? 'profile.activePseudonym' : 'profile.inactivePseudonym')| translate }}
                        </div>
                    </div>
                    <div class="micromate-profile-info" *ngIf="profileData.usePseudonym">
                        <div class="micromate-profile-info-title">
                            {{ 'profile.pseudonym'| translate }}:
                        </div>
                        <div class="micromate-profile-info-data">
                            {{ pseudonym }}
                        </div>
                    </div>
                </div>
                <div *ngIf="pseudonymEditorActive" class="micromate-profile-pseudonym-info">
                    <div class="micromate-profile-info">
                        <div class="micromate-profile-info-title">
                            {{ 'profile.usePseudonym'| translate }}:
                        </div>
                        <div class="micromate-profile-info-data">
                            <app-switch-toggle [formControl]="usePseudonymFormControl"></app-switch-toggle>
                        </div>
                    </div>
                    <div class="micromate-profile-info micromate-profile-info-pseudonym">
                        <div class="micromate-profile-info-title" *ngIf="usePseudonymFormControl.getRawValue()">
                            {{ 'profile.pseudonym'| translate }}:
                        </div>
                        <div class="micromate-profile-info-data" *ngIf="usePseudonymFormControl.getRawValue()">
                            <div class="micromate-profile-info-pseudonym-editor">
                                {{ pseudonym }}
                                <app-icon *ngIf="!(profilePseudonymService.generatingPseudonym$ | async)"
                                          (click)="generateNewPseudonym()"
                                          [icon]="'arrows-rotate-light'"
                                          class="micromate-profile-info-pseudonym-generation-button"></app-icon>
                                <mm-spinner *ngIf="profilePseudonymService.generatingPseudonym$ | async"
                                            class="micromate-profile-info-pseudonym-generation-spinner"></mm-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="micromate-profile-pseudonym-editor-buttons">
                        <app-button (click)="cancelPseudonym()" type="secondary"> {{ 'profile.cancel'| translate }}
                        </app-button>
                        <app-button (click)="savePseudonym()" type="primary" [disabled]="!pseudonymValueChange">
                            {{ 'profile.save'| translate }}
                        </app-button>
                    </div>
                </div>

            </div>
        </ng-container>
        <ng-container *ngIf="profileData.allowToChangeMonthlyReportActivation">
            <hr class="mm-profile-profile-separator">
            <div class="micromate-profile-info">
                <div class="micromate-profile-info-title">
                    {{ 'profile.monthlyReport'| translate }}:
                </div>
                <div class="micromate-profile-info-data">
                    <app-switch-toggle [formControl]="useMonthlyReportFormControl"
                                       (change)="changeMonthlyReport()"></app-switch-toggle>
                </div>
            </div>
        </ng-container>
    </div>
</div>
