import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TeamsContextService} from '../../core/teams-context.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {LearnerBadge} from '../../../../../micromate-learn-lib/src/lib/types/LearnerBadge';
import {Translation} from '../../../../../micromate-learn-lib/src/lib/services/Translation';
import {LearnerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/learner-ressource.service';
import {BadgesRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/badges-ressource.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {LoggerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {app} from '@microsoft/teams-js';

@Component({
    selector: 'mm-badge',
    templateUrl: './profile-badge.component.html',
    styleUrls: ['./profile-badge.component.scss']
})
export class ProfileBadgeComponent implements OnInit, OnDestroy {
    public teamsContextServiceSubscription: Subscription;
    public badges: BehaviorSubject<LearnerBadge[]> = new BehaviorSubject<LearnerBadge[]>(undefined);
    public badges$ = this.badges.asObservable();

    constructor(public teamsContextService: TeamsContextService,
                public badgesService: BadgesRessource,
                private learnerService: LearnerRessource,
                private translate: TranslateService,
                private ref: ChangeDetectorRef,
                private titleService: Title,
                private eventRecorderService: EventRecorderService,
                private loggerService: LoggerRessource) {
        this.titleService.setTitle('Micromate Profile');
    }

    public ngOnInit(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileBadge init');
        this.teamsContextServiceSubscription = this.teamsContextService.teamsContext$.subscribe(async teamsContext => {
            const userId = teamsContext.user.id;
            this.loggerService.logFrontendInfoToBackend(`ProfileBadge ${TeamsContextService.formatContext(teamsContext)}, userId: ${userId}`);
            if (userId === undefined) {
                throw new Error(`UserObjectId is undefined when open ProfileBadge, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            // eslint-disable-next-line no-null/no-null
            if (userId === null) {
                throw new Error(`UserObjectId is null when open ProfileBadge, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            if (userId.trim().length === 0) {
                throw new Error(`UserObjectId is empty when open ProfileBadge, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            await this.getLearnerData(userId, teamsContext);
            await this.recordTabEvents(userId, teamsContext.page.subPageId);
        });
    }

    public ngOnDestroy(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileBadge destroyed');

        if (this.teamsContextServiceSubscription !== undefined) {
            this.teamsContextServiceSubscription.unsubscribe();
        }
    }

    private async getLearnerData(msteamsId: string, teamsContext: app.Context): Promise<void> {
        const languageDataPromise = this.learnerService.getLearnerLanguage(msteamsId, teamsContext);
        const badgesPromise = this.badgesService.getLearnerBadges(msteamsId);
        const languageData = await languageDataPromise;
        if (languageData === undefined) {
            throw new Error(`Could not find language in badges for msteamsId: ${msteamsId}`);
        }
        this.translate.use(new Translation().getTranslationLanguage(languageData.language));
        this.badges.next(await badgesPromise);
        this.ref.detectChanges();
    }

    private async recordTabEvents(userId: string, subEntityId: string | { openInConversation: boolean; }): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEvent(userId, TabEventType.TabBadgeOpened,
            this.eventRecorderService.createTabOpenedEventPayload(subEntityId));
    }

}
