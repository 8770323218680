import {Component, Input} from '@angular/core';
import {Icons} from '../icon/icon.component';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    public isActionInProgress: boolean;

    @Input()
    public icon: Icons;

    @Input()
    public type: 'primary' | 'secondary' | 'secondary-dark' | 'ai' | 'transparent' | 'dangerous' = 'primary';

    @Input()
    public disabled: boolean = false;

    @Input()
    public action: () => Promise<void>;

    public async execute(): Promise<void> {
        if (!this.disabled && this.action !== undefined) {
            this.isActionInProgress = true;
            await this.action();
            this.isActionInProgress = false;
        }
    }
}
