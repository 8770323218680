<div class="mm-popup-container" [class.open]="isOpen">
    <div class="mm-popup-container-top">
        <div class="mm-popup-card" (click)="$event.stopPropagation()"
             [style.width.px]="popupOptions.size === 'custom' && popupOptions.customWidthPx !== undefined ? popupOptions.customWidthPx : null"
             [style.height.px]="popupOptions.size === 'custom' && popupOptions.customHeightPx !== undefined ? popupOptions.customHeightPx : null"
             [class.mm-popup-card-normal]="popupOptions.size === 'normal'"
             [class.mm-popup-card-small]="popupOptions.size === 'small'"
             [class.mm-popup-card-large]="popupOptions.size === 'large'">
            <div *ngIf="popupOptions.isClosable === 'closable' || popupOptions.isClosable === 'disable' || popupOptions.isClosable === 'closableWithoutEsc'"
                 (click)="close()" class="mm-popup-close-button">
                <app-icon icon="times-light"
                          [class.mm-popup-close-button-disable]="workInProgress || popupOptions.isClosable === 'disable'"></app-icon>
            </div>
            <div class="mm-popup-card-content">
                <ng-container #popupContent></ng-container>
            </div>
        </div>
    </div>
</div>
