import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {LearnAuthService} from '../../../core/authentication/learn-auth.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map, switchMap} from 'rxjs/operators';
import {NavigationService} from '../../../core/navigation.service';
import {AuthUntilUserRoleService} from '../../../core/authentication/auth-until-user-role.service';
import {DialogViewModelCreatorService} from 'botato-angular-lib';
import {DialogViewModel} from 'botato-angular-lib/lib/viewmodel/DialogViewModel';
import {LoggerRessource} from '../../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {User} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    selector: 'mm-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {
    @Input() activeNavigation: 'chat' | 'badges' | 'scoreboard' | 'study-progress';
    public isAuthenticated: boolean;
    public learnerId: string;

    public navigationOpen = false;
    public authServiceSubscription: Subscription;
    private getDialogViewModelSubscription: Subscription;

    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                private activatedRoute: ActivatedRoute,
                private translate: TranslateService,
                private logger: LoggerRessource,
                private titleService: Title,
                public navigationService: NavigationService,
                public authUntilUserRoleService: AuthUntilUserRoleService,
                private dialogViewModelCreatorService: DialogViewModelCreatorService) {
        this.titleService.setTitle('Micromate Learn');
        this.authServiceSubscription = this.activatedRoute.queryParamMap.pipe(
            map(params => {
                this.learnerId = params.get('learnerid') ?? undefined;
            }),
            switchMap(() => this.authService.authentication$))
            .subscribe(async (authentication: User) => {
                this.isAuthenticated = authentication !== undefined;
                const userLanguage = authentication?.uiLocale;
                if (userLanguage !== undefined && userLanguage.trim() !== this.translate.currentLang) {
                    this.translate.use(userLanguage.trim());
                }
            });

        this.getDialogViewModelSubscription = this.dialogViewModelCreatorService.getDialogViewModelObservable().subscribe((dialogViewModel: DialogViewModel) => {
            if (!this.navigationService.getActiveNavigation()) {
                this.navigationService.setActiveNavigation(dialogViewModel.messageGroups.length > 0);
            }
        });
    }

    public logout(): void {
        this.logger.logFrontendInfoToBackend(`Call logout manually by user on ${window.location.href}`);
        this.authService.removeSelectedUser();
        this.authService.startLogoutFlow();
    }

    public getLernerIdParam(): { learnerid: string; } {
        if (this.learnerId === undefined) {
            return undefined;
        }
        return {learnerid: this.learnerId};
    }

    public getLernerIdParamForTabs(): { openInConversation: 'true' | 'false'; learnerid?: string; } {
        const params: { openInConversation: 'true' | 'false'; learnerid?: string; } = {openInConversation: 'false'};
        if (this.learnerId === undefined) {
            return params;
        }
        params.learnerid = this.learnerId;
        return params;
    }

    public ngOnDestroy(): void {
        if (this.authServiceSubscription !== undefined) {
            this.authServiceSubscription.unsubscribe();
        }

        if (this.getDialogViewModelSubscription !== undefined) {
            this.getDialogViewModelSubscription.unsubscribe();
        }
    }

}
